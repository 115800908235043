import { Fragment, ReactNode } from 'react';
import { msg, Plural, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import {
  ArticleActionsCell,
  ArticleNameCell,
  ClientArticleCommentCell,
} from '@/components/ArticlesTableCells';
import {
  ArticleChoiceCell,
  ArticleChoiceSection,
  Choice,
  ChoiceRefusedRow,
  Choices,
} from '@/components/ArticlesTableCells/ArticleChoiceCell';
import BaseMoneyCell, {
  RequestOrganizationPrice,
} from '@/components/ArticlesTableCells/ArticleMoneyCell';
import ContactDetails from '@/components/ContactDetails';
import AlertBar from '@/design_system/AlertBar';
import Box from '@/design_system/Box';
import Stack from '@/design_system/Stack';
import Table from '@/design_system/Table';
import { Body, Cell, Column, Header, Row } from '@/design_system/Table/Table';
import { TableViewSpacebarWorkaround } from '@/design_system/Table/TableViewSpacebarWorkaround';
import IconStoreDeposit from '@/icons/StoreDeposit.svg';
import IconWarning from '@/icons/Warning.svg';
import { useGetArticleName } from '@/models/article';
import { ClientArticleWithRelations, ClientRequestWithRelations } from '@/models/request';
import { ClientArticleStepper } from '@/routes/Brand/Requests/Request/components/shared/ClientArticleStepper';
import { useClientToken, useStoreToken } from '@/services/auth';
import { formatDate } from '@/utils/date';
import useViewPort from '@/utils/useViewport';

import './ClientArticlesTable.css';

const ClientArticlesTable = ({
  request,
  mode = 'action',
  hideStepper = false,
  showPrice = false,
  showArticleComment = false,
  choices,
  setChoices,
  showChoicesError,
}: {
  request: ClientRequestWithRelations;
  mode?: 'need' | 'action';
  hideStepper?: boolean;
  showPrice?: boolean;
  showArticleComment?: boolean;
  choices?: Choices;
  setChoices?: (choices: Choices) => void;
  showChoicesError?: boolean;
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const clientToken = useClientToken();

  const showDepositedOn = !!request.store && !!request.client;

  let articlesToDisplay: ClientArticleWithRelations[] = [];
  let articlesToPickup: ClientArticleWithRelations[] = [];

  if (request.isArchived) {
    articlesToDisplay = request.allArticles;
  } else {
    const refusedArticlesAtValidationCurrentlyAtStore = request.articles.filter(
      (article) =>
        article.cancellationDetail?.statusWhenCancelled === 'pending-quote-acceptation' &&
        article.atStoreId
    );

    if (refusedArticlesAtValidationCurrentlyAtStore.length > 0) {
      articlesToPickup = refusedArticlesAtValidationCurrentlyAtStore;
      articlesToDisplay = request.articles.filter(
        (article) => !refusedArticlesAtValidationCurrentlyAtStore.find((a) => a.id === article.id)
      );
    } else {
      articlesToDisplay = request.articles;
    }
  }

  const showStepper = articlesToDisplay.length + articlesToPickup.length > 1 && !hideStepper;

  let list: ReactNode;

  if (isMobile) {
    list = (
      <>
        <Box padding="0" className="client-articles-list">
          <div className="client-articles-list__title">
            {clientToken ? (
              <Trans id="client.articles.list.title">My items</Trans>
            ) : (
              <Trans id="client.articles.list.title.store">The items</Trans>
            )}
          </div>
          {articlesToDisplay.map((article) => (
            <ArticleCard
              key={article.id}
              article={article}
              mode={mode}
              showPrice={showPrice}
              showStepper={showStepper}
              choice={choices?.[article.id]}
              setChoice={(choice) => setChoices?.({ ...choices, [article.id]: choice })}
              showChoicesError={showChoicesError}
            />
          ))}
        </Box>
      </>
    );
  } else {
    const alwaysShownColumns = 3;
    const columnCount =
      alwaysShownColumns + (!hideStepper ? 1 : 0) + (showPrice ? 1 : 0) + (choices ? 1 : 0);

    list = (
      <Box padding="0">
        {/* The choice column can add inputs to the table. This is currently kinda broken with React Aria
            To fix this, we need the TableViewSpacebarWorkaround component. (See component for more explanations) */}
        <TableViewSpacebarWorkaround>
          <Table
            aria-label={_(msg({ id: 'client.articles.table.label', message: 'Items' }))}
            className="client-articles-table"
          >
            <Header>
              <Column isRowHeader width="1fr" minWidth={240}>
                <Trans id="client.articles.table.column.article.title">Item</Trans>
              </Column>
              {showStepper && (
                <Column width={250}>
                  <Trans id="client.articles.table.column.status.title">Status</Trans>
                </Column>
              )}
              <Column width="1fr" minWidth={240}>
                {mode === 'action' && (
                  <Trans id="client.articles.table.column.services.title">Service required</Trans>
                )}
                {mode === 'need' && (
                  <Trans id="client.articles.table.column.defects.title">Noted defect</Trans>
                )}
              </Column>
              {showPrice && (
                <Column width={110} align="end">
                  <Trans id="client.articles.table.column.price.title">Price</Trans>
                </Column>
              )}
              {choices && (
                <Column
                  width={110}
                  align="end"
                  textValue={_(
                    msg({
                      id: 'client.articles.table.column.choices.title',
                      message: 'Choices',
                    })
                  )}
                />
              )}
            </Header>
            <Body>
              {articlesToDisplay.map((article) => (
                <Fragment key={article.id}>
                  <ArticleRow
                    key={article.id}
                    article={article}
                    mode={mode}
                    showStepper={showStepper}
                    showPrice={showPrice}
                    choice={choices?.[article.id]}
                    setChoice={(choice) => setChoices?.({ ...choices, [article.id]: choice })}
                    showChoicesError={showChoicesError}
                  />
                  {choices?.[article.id].value === 'refused' && (
                    <ChoiceRefusedRow
                      choice={choices[article.id]}
                      setChoice={(choice) => setChoices?.({ ...choices, [article.id]: choice })}
                      span={columnCount}
                      showChoicesError={showChoicesError}
                    />
                  )}
                  {showArticleComment && article.clientComment && (
                    <ClientCommentRow comment={article.clientComment} span={columnCount} />
                  )}
                </Fragment>
              ))}
            </Body>
          </Table>
        </TableViewSpacebarWorkaround>
      </Box>
    );
  }

  return (
    <Stack gap="2rem">
      {!request.isArchived && articlesToPickup.length > 0 && (
        <RefusedArticlesToPickup request={request} articles={articlesToPickup} />
      )}
      <Stack gap="0.5rem">
        {list}
        {showDepositedOn && (
          <Stack row flexWrap="initial" gap="0.25rem" className="text-secondary">
            <IconStoreDeposit style={{ fontSize: '1rem' }} />
            <p className="paragraph-200-regular">
              <Trans id="client.articles.deposited-on">
                Your items have been deposited on{' '}
                <span className="paragraph-200-bold text-primary">
                  {formatDate(request.articles[0]?.createdAtDate, { dateStyle: 'medium' })}
                </span>{' '}
                in the{' '}
                <span className="paragraph-200-bold text-primary">{request.store?.name}</span>{' '}
                store.
              </Trans>
            </p>
          </Stack>
        )}
        {showPrice && <RequestOrganizationPrice price={request.cost} />}
      </Stack>
    </Stack>
  );
};

const ArticleCard = ({
  article,
  mode,
  showPrice,
  showStepper,
  choice,
  setChoice,
  showChoicesError,
}: {
  article: ClientArticleWithRelations;
  mode: 'need' | 'action';
  showPrice: boolean;
  showStepper?: boolean;
  choice?: Choice;
  setChoice?: (choice: Choice) => void;
  showChoicesError?: boolean;
}) => {
  return (
    <Stack padding="16px" gap="0.75rem" className="client-articles-list__card">
      <Stack row gap="8px" alignItems="center" justifyContent="space-between">
        <ArticleNameCell article={article} />
        {showPrice && <BaseMoneyCell price={article.snapshot.cost} />}
      </Stack>
      {showStepper && <ClientArticleStepper size="medium" article={article} />}
      <ArticleActionsCell
        actions={article.snapshot.actions}
        customActions={article.snapshot.customActions}
        packActions={article.snapshot.packActions}
        mode={mode}
        showBonus={showPrice}
      />
      {choice && setChoice && (
        <ArticleChoiceSection
          choice={choice}
          setChoice={setChoice}
          showChoicesError={showChoicesError}
        />
      )}
      {article.clientComment && <ClientArticleCommentCell comment={article.clientComment} />}
      {choice && setChoice && (
        <ArticleChoiceSection
          choice={choice}
          setChoice={setChoice}
          showChoicesError={showChoicesError}
        />
      )}
    </Stack>
  );
};

const ArticleRow = ({
  article,
  mode,
  showStepper,
  showPrice,
  choice,
  setChoice,
  showChoicesError,
}: {
  article: ClientArticleWithRelations;
  mode: 'need' | 'action';
  showStepper: boolean;
  showPrice: boolean;
  choice?: Choice;
  setChoice?: (choice: Choice) => void;
  showChoicesError?: boolean;
}) => {
  return (
    <Row id={article.id} noBorder={!!article.clientComment || choice?.value === 'refused'}>
      <Cell>
        <ArticleNameCell article={article} />
      </Cell>
      {showStepper && (
        <Cell>
          <div style={{ width: '218px' }}>
            <ClientArticleStepper size="medium" article={article} />
          </div>
        </Cell>
      )}
      <Cell>
        <ArticleActionsCell
          actions={article.snapshot.actions}
          customActions={article.snapshot.customActions}
          packActions={article.snapshot.packActions}
          mode={mode}
          showBonus={showPrice}
        />
      </Cell>
      {showPrice && (
        <Cell align="end">
          <BaseMoneyCell price={article.snapshot.cost} />
        </Cell>
      )}
      {choice && setChoice && (
        <Cell align="end">
          <ArticleChoiceCell
            choice={choice}
            setChoice={setChoice}
            showChoicesError={showChoicesError}
          />
        </Cell>
      )}
    </Row>
  );
};

const ClientCommentRow = ({ comment, span }: { comment: string; span: number }) => {
  return (
    <Row>
      <Cell colSpan={span} style={{ paddingTop: 0 }} align="stretch">
        <ClientArticleCommentCell comment={comment} />
      </Cell>
    </Row>
  );
};

const RefusedArticlesToPickup = ({
  request,
  articles,
}: {
  request: ClientRequestWithRelations;
  articles: ClientArticleWithRelations[];
}) => {
  const getArticleName = useGetArticleName();
  const storeToken = useStoreToken();

  if (storeToken) {
    return (
      <AlertBar
        title={
          <Trans id="client.articles.refused-articles-to-pickup.message.non-embedded">
            The client refused the services for{' '}
            <Plural value={articles.length} one="# item" other="# items" /> -{' '}
            {articles.map((article) => getArticleName({ article })).join(',')} - of the initial
            request. They have been notified to come pick{' '}
            <Plural value={articles.length} one="it" other="them" /> up at your store.
          </Trans>
        }
        type="warning"
        icon={<IconWarning />}
        size="large"
      />
    );
  }

  return (
    <AlertBar
      title={
        <Trans id="client.articles.refused-articles-to-pickup.message">
          You refused the services for{' '}
          <Plural value={articles.length} one="# item" other="# items" /> -{' '}
          {articles.map((article) => getArticleName({ article })).join(',')} - of your initial
          request. Please, come pick <Plural value={articles.length} one="it" other="them" /> up at
          the {request.store?.name} store:
        </Trans>
      }
      type="warning"
      icon={<IconWarning />}
      size="large"
    >
      <ContactDetails
        type="store"
        email={request.store?.address.contactEmail}
        address={request.store?.address}
        phone={request.store?.phone}
      />
    </AlertBar>
  );
};

export default ClientArticlesTable;
