import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import IconBag from '@/icons/Bag.svg';
import { useMedia, useMediaQueries } from '@/models/medium';
import { ClientRequestWithRelations, useClientRequest } from '@/models/request';
import Header from '@/routes/Brand/components/Header';
import OrganizationLogo from '@/routes/Brand/components/OrganizationLogo';
import { useClientToken, useCurrentOrganization, useStoreToken } from '@/services/auth';
import { ErrorBoundary } from '@/services/sentry';
import { createBEMClasses } from '@/utils/classname';
import { REQUEST_IN_PROGRESS_LOCAL_STORAGE_KEY } from '@/utils/requestInProgress';
import useViewPort from '@/utils/useViewport';

import ActionsForm from './components/Article/components/ActionsForm';
import AddAnotherAction from './components/Article/components/AddAnotherAction';
import ArticleForm from './components/Article/components/ArticleForm';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import SettingsForm from './components/SettingsForm';
import Welcome from './components/Welcome';

import './New.css';

const { block, element } = createBEMClasses('client-new-request');

const New = () => {
  const [organization] = useCurrentOrganization();
  const { isMobile } = useViewPort();
  const navigate = useNavigate();
  const clientToken = useClientToken();
  const storeToken = useStoreToken();

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id') ?? '';

  const {
    data: request,
    isLoading: isLoadingRequest,
    isError: isErrorRequest,
  } = useClientRequest(id);

  useEffect(() => {
    if (isErrorRequest) {
      navigate('');
    }
  }, [isErrorRequest, navigate]);

  // Redirect to the request page if the request is not a draft
  useEffect(() => {
    if (request?.draft === false) {
      const tokenQueryParam = clientToken
        ? `clientToken=${clientToken}`
        : `storeToken=${storeToken}`;
      navigate(`/brand/${organization!.slug}/requests/${request.id}?${tokenQueryParam}`);
    }
  }, [organization, request, navigate, clientToken, storeToken]);

  const [showCart, setShowCart] = useState(false);

  const [globalStep, setGlobalStep] = useState<'create' | 'checkout'>('create');

  return (
    <>
      <Header organization={organization}>
        <Stack row gap="1rem" alignItems="center" justifyContent="space-between">
          <Stack row gap={isMobile ? '0.5rem' : '1rem'} alignItems="center">
            <OrganizationLogo organization={organization} size="small" />
          </Stack>
          {request && globalStep === 'create' && (
            <div className={element('cart-button')}>
              <Button
                iconOnly
                noBorder
                variant="secondary-brand"
                size="large"
                onPress={() => setShowCart(!showCart)}
              >
                <IconBag />
              </Button>
              {!!request?.actionQuantity && (
                <div className="paragraph-200-medium">{request.actionQuantity}</div>
              )}
            </div>
          )}
          {request && globalStep === 'checkout' && (
            <Button variant="secondary-brand" size="medium" onPress={() => setGlobalStep('create')}>
              <Trans id="client.new.header.cancel">Cancel</Trans>
            </Button>
          )}
        </Stack>
      </Header>
      <main className={block()}>
        <ErrorBoundary>
          {isLoadingRequest ? null : request ? (
            <>
              {globalStep === 'create' && (
                <CreateRequestSteps
                  request={request}
                  showCart={showCart}
                  setShowCart={setShowCart}
                  onValidateRequest={() => setGlobalStep('checkout')}
                />
              )}
              {globalStep === 'checkout' && (
                <Checkout request={request} onEditCart={() => setGlobalStep('create')} />
              )}
            </>
          ) : (
            <Welcome />
          )}
        </ErrorBoundary>
      </main>
    </>
  );
};

const CreateRequestSteps = ({
  request,
  showCart,
  setShowCart,
  onValidateRequest,
}: {
  request: ClientRequestWithRelations;
  showCart: boolean;
  setShowCart: (showCart: boolean) => void;
  onValidateRequest: () => void;
}) => {
  const navigate = useNavigate();

  const [step, setStep] = useState<'settings' | 'article' | 'service' | 'service-added'>(() => {
    if (request.articles.length === 0) {
      return request.storeId ? 'settings' : 'article';
    } else {
      const latestArticleHasNoActions =
        request.articles[request.articles.length - 1].snapshot.actions.length === 0 &&
        request.articles[request.articles.length - 1].snapshot.customActions.length === 0;

      return latestArticleHasNoActions ? 'service' : 'service-added';
    }
  });

  const mediaQueries = useMediaQueries(
    request.articles.map((article) => ({
      types: ['article-photo', 'proof-of-purchase'],
      articleId: article?.id,
      limit: 100,
    }))
  );

  const [articleIndex, setArticleIndex] = useState(
    request.articles.length ? request.articles.length - 1 : 0
  );
  const [actionId, setActionId] = useState<string | undefined>();
  const [customActionId, setCustomActionId] = useState<string | undefined>();

  const article = request.articles[articleIndex];
  const action = article?.snapshot.actions.find((action) => action.id === actionId);
  const customAction = article?.snapshot.customActions.find(
    (customAction) => customAction.id === customActionId
  );

  const mediaQuery = mediaQueries[articleIndex];

  const { data: { media: defectPhotos } = { media: [] }, isLoading: isDefectPhotosLoading } =
    useMedia({
      types: ['defect-photo'],
      actionId,
      customActionId,
    });

  return (
    <>
      {step === 'settings' && (
        <SettingsForm request={request} onContinue={() => setStep('article')} />
      )}
      {step === 'article' && !mediaQuery?.isLoading && (
        <ArticleForm
          request={request}
          onSaveArticle={() => {
            if (!article) {
              // User created a new article
              setStep('service');
            } else if (!article.hasActions) {
              // User edited an article which has no actions yet
              setStep('service');
            } else if (action || customAction) {
              // User edited an article and was editing an action
              setStep('service');
            } else {
              // User edited an article which has actions
              setStep('service-added');
            }
          }}
          onDiscard={() => {
            if (!request.articles.length) {
              // User discarded creating the first article
              if (request.storeId) {
                setStep('settings');
              } else {
                window.localStorage.removeItem(REQUEST_IN_PROGRESS_LOCAL_STORAGE_KEY);
                navigate(`/brand/${request.organization.slug}/requests/new`);
              }
            } else if (!article) {
              // User discarded creating a new article, and there are other articles already
              setArticleIndex(request.articles.length - 1);
              setStep('service-added');
              setShowCart(true);
            } else if (!article.hasActions) {
              // User discarded editing an article which has no actions yet
              setStep('service');
            } else if (action || customAction) {
              // User discarded editing an article and was editing an action
              setStep('service');
            } else {
              // User discarded editing an article which already has actions
              setStep('service-added');
            }
          }}
          initialArticle={article}
          initialMedia={mediaQuery?.data?.media}
          key={article?.id}
        />
      )}
      {step === 'service' && article && !isDefectPhotosLoading && (
        <ActionsForm
          initialAction={action}
          initialCustomAction={customAction}
          initialMedia={defectPhotos}
          request={request}
          article={article}
          onEditArticle={() => {
            // User wants to edit the article
            setStep('article');
          }}
          onDeleteArticle={() => {
            setActionId(undefined);
            setCustomActionId(undefined);

            if (request.articles.length > 1) {
              // User deleted the article, and there are other articles
              setArticleIndex(
                articleIndex === request.articles.length - 1
                  ? request.articles.length - 2
                  : request.articles.length - 1
              );
              setStep('service-added');
              setShowCart(true);
            } else {
              // User deleted the article, and there are no other articles
              setArticleIndex(0);
              setStep('article');
            }
          }}
          onAddAction={() => {
            if (action || customAction) {
              // User edited an action
              setActionId(undefined);
              setCustomActionId(undefined);
              setStep('service-added');
            } else {
              // User added a new action
              setStep('service-added');
            }
          }}
          onCancel={() => {
            if (action || customAction) {
              // User canceled editing an action
              setActionId(undefined);
              setCustomActionId(undefined);
              setStep('service-added');
            } else {
              // User canceled adding a new action on an article which already has actions
              setStep('service-added');
            }
          }}
        />
      )}
      {step === 'service-added' && article && (
        <AddAnotherAction
          request={request}
          article={article}
          onEditArticle={() => {
            // User wants to edit the article
            setStep('article');
          }}
          onDeleteArticle={() => {
            if (request.articles.length > 1) {
              // User deleted the article, and there are other articles
              setArticleIndex(
                articleIndex === request.articles.length - 1
                  ? request.articles.length - 2
                  : request.articles.length - 1
              );
              setStep('service-added');
              setShowCart(true);
            } else {
              // User deleted the article, and there are no other articles
              setArticleIndex(0);
              setStep('article');
            }
          }}
          onYes={() => {
            // User wants to add a new action
            setActionId(undefined);
            setCustomActionId(undefined);
            setStep('service');
          }}
          onNo={() => {
            // User doesn't want any more actions
            setShowCart(true);
          }}
        />
      )}
      <Cart
        isOpen={showCart}
        onOpenChange={setShowCart}
        request={request}
        onAddArticle={() => {
          setArticleIndex(request.articles.length);
          setStep('article');
          setShowCart(false);
        }}
        onAddActionToArticle={(index) => {
          setArticleIndex(index);
          setStep('service');
          setShowCart(false);
        }}
        onEditActionOnArticle={(data) => {
          setStep('service');
          setArticleIndex(data.articleIndex);
          if ('actionId' in data) {
            setActionId(data.actionId);
            setCustomActionId(undefined);
          } else {
            setCustomActionId(data.customActionId);
            setActionId(undefined);
          }
          setShowCart(false);
        }}
        onEditArticle={(index) => {
          setArticleIndex(index);
          setStep('article');
          setShowCart(false);
        }}
        onDeleteAllArticles={() => {
          setArticleIndex(0);
          setStep('article');
          setShowCart(false);
        }}
        onDeleteArticle={(index) => {
          if (index === articleIndex) {
            setStep('service-added');
            setArticleIndex(0);
          }
        }}
        onValidateRequest={onValidateRequest}
      />
    </>
  );
};

export default New;
