import { useEffect, useState } from 'react';
import { msg, Plural, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';

import { Endpoints } from '@/api';
import { Choices } from '@/components/ArticlesTableCells/ArticleChoiceCell';
import Loader from '@/components/Loader';
import Button from '@/design_system/Button';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import { BrandWrapper, BrandWrapperCenter, BrandWrapperFooter } from '@/layouts/Brand';
import { useArticleName } from '@/models/article';
import {
  ClientRequestWithRelations,
  usePayment,
  usePaymentChoice,
  useRequest,
  useStartPayment,
} from '@/models/request';
import ClientArticlesTable from '@/routes/Brand/Requests/Request/components/shared/ClientArticlesTable';
import ClientInfo from '@/routes/Brand/Requests/Request/components/shared/ClientInfo';
import ClientStepper from '@/routes/Brand/Requests/Request/components/shared/ClientStepper';
import { PickupPointInfo } from '@/routes/Brand/Requests/Request/components/shared/PickupPointInfo/PickupPointInfo';
import { useStripe } from '@/services/stripe';

import './Payment.css';

const Payment = ({ request }: { request: ClientRequestWithRelations }) => {
  const { _ } = useLingui();

  const { data: payment, isLoading: isLoadingPayment } = usePayment({ requestId: request.id });
  const { mutateAsync: startPayment } = useStartPayment();
  const [paymentError, setPaymentError] = useState<string>();
  const [clientSecret, setClientSecret] = useState<string>();
  const isReturnFromCheckout = new URLSearchParams(window.location.search).get('return');

  if (isLoadingPayment) {
    return (
      <BrandWrapper>
        <Loading>
          <Trans id="_general.loading">Loading...</Trans>
        </Loading>
      </BrandWrapper>
    );
  }

  if (
    isReturnFromCheckout !== null ||
    payment?.status !== 'unstarted' ||
    clientSecret === 'cs_mock_secret'
  ) {
    return (
      <BrandWrapper>
        <PaymentStatus request={request} />
      </BrandWrapper>
    );
  }

  if (clientSecret) {
    return (
      <BrandWrapper>
        <PaymentCheckout clientSecret={clientSecret} />
      </BrandWrapper>
    );
  }

  return (
    <BrandWrapper>
      <PaymentWelcome
        request={request}
        onAccept={({ shouldStartPayment }: { shouldStartPayment: boolean }) => {
          if (shouldStartPayment) {
            startPayment({ requestId: request.id })
              .then(({ clientSecret }) => setClientSecret(clientSecret))
              .catch((err) => {
                console.error(err);
                setPaymentError(
                  (err.message as string) ??
                    _(msg({ id: '_general.error.unknown', message: 'Unknown error' }))
                );
              });
          }
        }}
        paymentError={paymentError}
      />
    </BrandWrapper>
  );
};

const PaymentWelcome = ({
  request,
  onAccept,
  paymentError,
}: {
  request: ClientRequestWithRelations;
  onAccept: ({ shouldStartPayment }: { shouldStartPayment: boolean }) => void;
  paymentError?: string;
}) => {
  const {
    mutateAsync: sendPaymentChoice,
    isPending: isPendingSendPaymentChoice,
    isSuccess: isSuccessSendPaymentChoice,
  } = usePaymentChoice();

  const activeArticlesInPaymentStep = request.articles.filter(
    (article) => article.step?.step === 'payment' && !article.cancelledAt
  );

  const [choices, setChoices] = useState<Choices>(
    activeArticlesInPaymentStep.reduce(
      (acc, article) => ({
        ...acc,
        [article.id]: {
          value: null,
          reason: null,
          otherReason: null,
        },
      }),
      {}
    )
  );
  const [showChoicesError, setShowChoicesError] = useState(false);
  const choiceMissing = Object.values(choices).some((choice) => !choice.value);
  const reasonMissing = Object.values(choices).some(
    (choice) =>
      choice.value === 'refused' &&
      (!choice.reason || (choice.reason === 'other' && !choice.otherReason))
  );
  const [error, setError] = useState<string | null>(null);

  const firstArticleName = useArticleName({
    article: activeArticlesInPaymentStep[0],
    type: 'short',
  });

  const atLeastOneNonFreeArticle = activeArticlesInPaymentStep.some(
    (article) => article.snapshot.cost!.amount > 0
  );

  const acceptedArticles = activeArticlesInPaymentStep.filter(
    (article) => choices[article.id].value === 'accepted'
  );
  const isFree =
    acceptedArticles.map((article) => article.snapshot.cost!.amount).reduce((a, b) => a + b, 0) ===
    0;

  const isInternalPayment = activeArticlesInPaymentStep.find(
    (article) => article.step?.step === 'payment' && article.step?.config.externalPayment === false
  );

  // This can happen if `startPayment` fails and the user refreshes the page
  const allArticlesAlreadyHaveAPaymentChoice = activeArticlesInPaymentStep.every(
    (article) =>
      article.paymentAcceptedAt || article.paymentRefusedAt || article.freeFinalQuoteAcceptedAt
  );
  const shouldShowChoices = !allArticlesAlreadyHaveAPaymentChoice && isInternalPayment;

  function onSubmit() {
    if (allArticlesAlreadyHaveAPaymentChoice) {
      onAccept({ shouldStartPayment: true });
      return;
    }

    if (choiceMissing || reasonMissing) {
      setShowChoicesError(true);
      return;
    }

    setError(null);

    sendPaymentChoice({
      id: request.id,
      choices: choices as Endpoints['POST /requests/:id/payment-choice']['body']['choices'],
    })
      .then(() => {
        onAccept({ shouldStartPayment: !isFree });
      })
      .catch((error) => {
        setError(error.message as string);
      });
  }

  return (
    <>
      <BrandWrapperCenter>
        <Stack gap="1.5rem">
          <ClientStepper step="payment" request={request} />
          <Stack gap="0.5rem" style={{ marginBottom: '1rem' }}>
            <h1 className="headline-200-bold headline-300-bold-mobile">
              {atLeastOneNonFreeArticle ? (
                <Trans id="client.request.payment.welcome.title.paying">
                  A payment is required from your side
                </Trans>
              ) : (
                <Trans id="client.request.payment.welcome.title.free">Care & Repair quote</Trans>
              )}
            </h1>
            <p className="paragraph-50-regular paragraph-100-regular-mobile">
              <Trans id="client.request.payment.welcome.description">
                Our experts have confirmed the required actions for{' '}
                <Plural
                  value={activeArticlesInPaymentStep.length}
                  one={
                    <span>
                      your{' '}
                      <span className="paragraph-50-medium paragraph-100-medium-mobile">
                        {firstArticleName}
                      </span>
                    </span>
                  }
                  other={
                    <span>
                      your{' '}
                      <span className="paragraph-50-medium paragraph-100-medium-mobile">
                        # items
                      </span>
                    </span>
                  }
                />
                .
              </Trans>{' '}
              {isInternalPayment && atLeastOneNonFreeArticle && (
                <Trans id="client.request.payment.welcome.please">
                  Please now proceed to payment.
                </Trans>
              )}
              {!atLeastOneNonFreeArticle && (
                <b>
                  <Trans id="client.request.payment.welcome.free">
                    And good news, no payment will be required on your side!
                  </Trans>
                </b>
              )}
            </p>
          </Stack>
          <Stack gap="1rem">
            <PickupPointInfo request={request} />
            <ClientArticlesTable
              request={request}
              showPrice
              showArticleComment
              choices={shouldShowChoices ? choices : undefined}
              setChoices={shouldShowChoices ? setChoices : undefined}
              showChoicesError={shouldShowChoices ? showChoicesError : undefined}
            />
            <ClientInfo request={request} />
          </Stack>
          {paymentError && (
            <Message type="error" style={{ marginTop: '2rem' }}>
              <b>{paymentError}</b>
            </Message>
          )}
        </Stack>
      </BrandWrapperCenter>
      {isInternalPayment && (
        <BrandWrapperFooter>
          <Stack gap="0.5rem" style={{ flex: 1 }}>
            {showChoicesError && (choiceMissing || reasonMissing || error) && (
              <p className="paragraph-100-medium text-center text-danger">
                {choiceMissing ? (
                  <Trans id="client.request.payment.error.choice-missing">
                    Please accept or refuse the proposal for each item
                  </Trans>
                ) : reasonMissing ? (
                  <Trans id="client.request.payment.error.reason-missing">
                    Please select a reason for each refusal
                  </Trans>
                ) : (
                  error
                )}
              </p>
            )}
            <Button
              variant="brand"
              size="large"
              onPress={onSubmit}
              disabled={isPendingSendPaymentChoice || isSuccessSendPaymentChoice}
              isLoading={isPendingSendPaymentChoice || isSuccessSendPaymentChoice}
            >
              {!isFree && (
                <Trans id="client.request.payment.welcome.accept">
                  Confirm & Proceed to payment
                </Trans>
              )}
              {(isFree || allArticlesAlreadyHaveAPaymentChoice) && (
                <Trans id="client.request.payment.welcome.accept.free">Confirm</Trans>
              )}
            </Button>
          </Stack>
        </BrandWrapperFooter>
      )}
    </>
  );
};

export const PaymentCheckout = ({ clientSecret }: { clientSecret: string }) => {
  const stripe = useStripe();

  if (!stripe) {
    return (
      <Loading>
        <Trans id="_general.loading">Loading...</Trans>
      </Loading>
    );
  }

  return (
    <Stack padding="16px" style={{ flex: 1, backgroundColor: '#f7f8f7' }}>
      {clientSecret && (
        <EmbeddedCheckoutProvider stripe={stripe} options={{ clientSecret }}>
          <EmbeddedCheckout className="stripe-checkout" />
        </EmbeddedCheckoutProvider>
      )}
    </Stack>
  );
};

const PaymentStatus = ({ request }: { request: ClientRequestWithRelations }) => {
  const { data: payment } = usePayment(
    {
      requestId: request.id,
    },
    {
      refetchInterval: ({ state: { data } }) => (data?.status !== 'succeeded' ? 1000 : false),
    }
  );
  const { refetch } = useRequest(request.id);

  useEffect(() => {
    if (payment?.status) {
      refetch();
    }
  }, [payment?.status, refetch]);

  return (
    <Loading>
      <Trans id="client.request.payment.in-progress">
        Your payment is being processed, please wait...
      </Trans>
    </Loading>
  );
};

const Loading = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack
      style={{ height: '100%' }}
      gap="1rem"
      alignItems="center"
      flexWrap="nowrap"
      justifyContent="center"
    >
      <Loader style={{ height: '40px', width: '40px' }} />
      <p className="paragraph-100-regular">{children}</p>
    </Stack>
  );
};

export default Payment;
