import { forwardRef, Ref } from 'react';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { CreationStepConfig } from '@/api';
import AlertBar from '@/design_system/AlertBar';
import Message from '@/design_system/Message';
import Stack from '@/design_system/Stack';
import { useActionTypes } from '@/models/actionType';
import { ArticleWithRelations, RequestWithRelations } from '@/models/request';
import ArticleDispatch from '@/routes/Requests/components/ArticleDispatch';
import ActionTypes from '@/routes/Requests/Request/Article/ActionTypes';
import ColorSize from '@/routes/Requests/Request/Article/ColorSize';
import { Brand } from '@/routes/Requests/Request/Article/components/Brand';
import {
  ProductCategoryL1,
  ProductCategoryL2,
  ProductCategoryL3,
} from '@/routes/Requests/Request/Article/components/ProductCategories';
import ProductInfo from '@/routes/Requests/Request/Article/ProductInfo';
import { ProductPhoto } from '@/routes/Requests/Request/Article/ProductPhoto';

import { ArticlePhotoUpload } from './ArticlePhotoUpload';

export const ArticleDetails = ({
  request,
  article,
  showErrors,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  showErrors: boolean;
}) => {
  const errors = useArticleDetailsError(article);

  return (
    <>
      <Stack row mobileColumn gap="1rem">
        <ProductInfo request={request} article={article} key={article.productId} />

        <div className="requests__new__articles__new__product-or paragraph-100-regular">
          <hr />
          <span>
            <Trans id="_general.or">or</Trans>
          </span>
          <hr />
        </div>

        <ProductCategoryL1 request={request} article={article} />
      </Stack>

      {showErrors && !!errors.productError && <Message type="error">{errors.productError}</Message>}

      <ProductCategoryL2
        request={request}
        article={article}
        l2Error={showErrors && errors.productL2Error ? errors.productL2Error : undefined}
      />

      {!!request.organization.brands.length && (
        <Brand
          request={request}
          article={article}
          otherBrandError={
            showErrors && errors.otherBrandError ? errors.otherBrandError : undefined
          }
        />
      )}

      <ProductCategoryL3
        request={request}
        article={article}
        l3Error={showErrors && errors.productL3Error ? errors.productL3Error : undefined}
      />

      <ColorSize request={request} article={article} />

      <Stack row gap="1rem">
        <div style={{ flex: '1 1 200px' }}>
          <ArticlePhotoUpload
            article={article}
            error={showErrors ? errors.articlePhotoError : undefined}
          />
        </div>

        {!!article.product?.data.image && (
          <div style={{ flex: '1 1 200px' }}>
            <ProductPhoto
              productName={article.product.name}
              productImage={article.product.data.image}
            />
          </div>
        )}
      </Stack>
    </>
  );
};

export const ArticleServices = ({
  request,
  article,
  showErrors,
}: {
  request: RequestWithRelations;
  article: ArticleWithRelations;
  showErrors: boolean;
}) => {
  const creationConfig = article.step?.config as CreationStepConfig['config'] | undefined;

  const errors = useArticleServicesError(article);

  return (
    <>
      <ActionTypes
        request={request}
        article={article}
        mode={creationConfig?.requireCost || creationConfig?.requirePrice ? 'both' : 'need'}
        showWorkshopPrice={creationConfig?.requireCost}
        showOrganizationPrice={creationConfig?.requirePrice}
        error={showErrors ? errors.actionsError : undefined}
      />
      {creationConfig?.requireDispatch && (
        <ArticleDispatch
          request={request}
          article={article}
          error={showErrors ? errors.workshopError : undefined}
          disabled={false}
        />
      )}
    </>
  );
};

export const useHasNoAvailableActions = (article: ArticleWithRelations) => {
  const { data: actionTypesData } = useActionTypes(
    {
      requestId: article.requestId,
      productL1: article.productL1 ?? undefined,
      productL2: article.productL2 ?? undefined,
      productL3: article.productL3 ?? undefined,
    },
    {
      enabled:
        article.snapshot.actions.length === 0 &&
        article.snapshot.customActions.length === 0 &&
        article.snapshot.packActions.length === 0 &&
        !!article.productL1,
      keepPreviousData: true,
    }
  );

  const hasNoAvailableActions =
    actionTypesData?.actionTypes?.length === 0 && actionTypesData?.packActionTypes?.length === 0;

  return hasNoAvailableActions;
};

export const getArticleDetailsError = ({ article }: { article: ArticleWithRelations }) => {
  const productError =
    !article.product && !article.productL1
      ? msg({
          id: 'requests.articles.form.error.product',
          message: 'Please select an item reference or a type',
        })
      : undefined;

  const productL2Error =
    !article.product && article.productL1 && !article.productL2
      ? msg({ id: 'requests.articles.form.error.productL2', message: 'Please select a category' })
      : undefined;

  const productL3Error =
    !article.product && article.productL1 && !article.productL3
      ? msg({ id: 'requests.articles.form.error.productL3', message: 'Please select a material' })
      : undefined;

  const otherBrandError =
    article.data.brand?.isOther && !article.data.brand?.name
      ? msg({
          id: 'article.form.brand.other.specify-name.error',
          message: 'Please select a brand name',
        })
      : undefined;

  const articlePhotoError = !article.articlePhoto
    ? msg({ id: 'requests.articles.form.error.photo', message: 'Please add a full item picture' })
    : undefined;

  return {
    productError,
    productL2Error,
    productL3Error,
    otherBrandError,
    articlePhotoError,
    hasError:
      !!productError ||
      !!productL2Error ||
      !!productL3Error ||
      !!otherBrandError ||
      !!articlePhotoError,
  };
};

export const useArticleDetailsError = (article: ArticleWithRelations) => {
  const { _ } = useLingui();
  const {
    productError,
    productL2Error,
    productL3Error,
    otherBrandError,
    articlePhotoError,
    hasError,
  } = getArticleDetailsError({
    article,
  });

  return {
    productError: productError ? _(productError) : undefined,
    productL2Error: productL2Error ? _(productL2Error) : undefined,
    productL3Error: productL3Error ? _(productL3Error) : undefined,
    otherBrandError: otherBrandError ? _(otherBrandError) : undefined,
    articlePhotoError: articlePhotoError ? _(articlePhotoError) : undefined,
    hasError,
  };
};

export const getArticleServicesError = ({
  article,
  hasNoAvailableActions,
}: {
  article: ArticleWithRelations;
  hasNoAvailableActions?: boolean;
}) => {
  const creationConfig = article.step?.config as CreationStepConfig['config'] | undefined;
  const requireDispatch = creationConfig?.requireDispatch === true;
  const requireCost = creationConfig?.requireCost === true;
  const requirePrice = creationConfig?.requirePrice === true;

  const noActionsError =
    article.snapshot.actions.length === 0 &&
    article.snapshot.customActions.length === 0 &&
    article.snapshot.packActions.length === 0
      ? hasNoAvailableActions
        ? msg({
            id: 'requests.articles.form.error.actions-custom',
            message: 'No defects are available for this item. Please add a custom defect.',
          })
        : msg({
            id: 'requests.articles.form.error.actions',
            message: 'Please select at least one service',
          })
      : undefined;

  const costError =
    requireCost &&
    article.snapshot.customActions.some(
      (customAction) => customAction.costAmountWithoutDiscount === null
    )
      ? msg({
          id: 'article.estimate.actions.error.custom-actions-no-cost',
          message: 'Please provide cost details for the custom actions',
        })
      : undefined;

  const priceError =
    requirePrice &&
    article.snapshot.customActions.some(
      (customAction) => customAction.priceAmountWithoutDiscount === null
    )
      ? msg({
          id: 'article.estimate.actions.error.custom-actions-no-price',
          message: 'Please provide price details for the custom actions',
        })
      : undefined;

  const actionsError = noActionsError ?? costError ?? priceError;

  const workshopError =
    requireDispatch && !article.workshopId
      ? msg({ id: 'requests.articles.form.error.workshop', message: 'Please select a workshop' })
      : undefined;

  return {
    actionsError,
    workshopError,
    hasError: !!actionsError || !!workshopError,
  };
};

export const useArticleServicesError = (article: ArticleWithRelations) => {
  const { _ } = useLingui();

  const hasNoAvailableActions = useHasNoAvailableActions(article);

  const { actionsError, workshopError, hasError } = getArticleServicesError({
    article,
    hasNoAvailableActions,
  });

  return {
    actionsError: actionsError ? _(actionsError) : undefined,
    workshopError: workshopError ? _(workshopError) : undefined,
    hasError,
  };
};

export const ArticleFormGlobalError = forwardRef(function ArticleFormGlobalError(
  {
    showGlobalError,
    showNoActionsWarning,
  }: { showGlobalError: boolean; showNoActionsWarning: boolean },
  ref: Ref<HTMLDivElement>
) {
  return (
    <Stack
      ref={ref}
      style={{ marginBottom: showGlobalError || showNoActionsWarning ? '1rem' : undefined }}
      gap="0.5rem"
    >
      {showGlobalError && (
        <AlertBar
          type="error"
          title={
            <Trans id="requests.edit.articles.alert.missing-info">
              Please fill the missing information.
            </Trans>
          }
        />
      )}
      {showNoActionsWarning && (
        <AlertBar
          type="warning"
          title={
            <Trans id="requests.edit.articles.alert.no-catalog-actions">
              No services available for this item. Please add a custom defect to add item to the
              request.
            </Trans>
          }
        />
      )}
    </Stack>
  );
});
