import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { msg, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { TransitStepConfig } from '@/api';
import { DiscardChangesDialog } from '@/components/DiscardChangesDialog/DiscardChangesDialog';
import {
  PageLayout,
  PageLayoutContent,
  PageLayoutLeftPart,
  PageLayoutLeftPartContent,
  PageLayoutLeftPartFooter,
  PageLayoutSimpleHeader,
} from '@/components/PageLayout';
import AlertBar from '@/design_system/AlertBar';
import Button from '@/design_system/Button';
import Stack from '@/design_system/Stack';
import { AddressWithRelations } from '@/models/address';
import { useRequest } from '@/models/request';
import {
  ArticleWithRelations,
  Shipment,
  ShipmentCarrierService,
  ShipmentHandover,
  useCreateShipment,
} from '@/models/shipment';
import ShipmentForm from '@/routes/Shipments/Shipment/components/ShipmentForm';
import { newAddressWithRelations } from '@/routes/Shipments/Shipment/components/ShipmentForm/components/PlaceSelect';
import { useCurrentSession } from '@/services/auth';
import { useShowWarningBeforeLeavingApp, useSimpleBlocker } from '@/utils/navigation';
import { useScrollIntoView } from '@/utils/useScrollIntoView';
import useViewPort from '@/utils/useViewport';

const New = () => {
  const blocker = useSimpleBlocker();
  useShowWarningBeforeLeavingApp();

  return (
    <>
      <NewDrawerWrapper />
      {blocker.state === 'blocked' && (
        <DiscardChangesDialog
          onConfirm={() => blocker.proceed()}
          onCancel={() => blocker.reset()}
        />
      )}
    </>
  );
};

const NewDrawerWrapper = () => {
  const { state } = useLocation();
  const [requestId] = useState(state?.requestId as string);
  const { currentSession } = useCurrentSession();
  const navigate = useNavigate();

  const { data: request, isLoading: isLoadingRequest } = useRequest(requestId);

  if (!!currentSession && !currentSession.canCreateShipments()) {
    navigate('/shipments', { state: { skipRouterBlocker: true } });
    return null;
  }

  if (!requestId) {
    return <NewDrawer />;
  }

  if (isLoadingRequest) {
    return null;
  }

  if (!request) {
    navigate('/shipments', { state: { skipRouterBlocker: true } });
    return null;
  }

  const storeAddress = request.store?.address
    ? newAddressWithRelations(request.store.address, null, request.store, null)
    : undefined;
  const clientAddress = request.client?.address
    ? newAddressWithRelations(request.client.address, request.client, null, null)
    : undefined;

  const transitNode = request.articles.find(
    (article) =>
      article.step?.step === 'transit' &&
      !(
        article.step.config.originType === 'store' &&
        article.step.config.destinationType === 'client'
      ) &&
      !(
        article.step.config.originType === 'client' &&
        article.step.config.destinationType === 'workshop'
      )
  )?.step!.id;

  const articlesReadyForShipment = transitNode
    ? request.articles
        .filter((article) => article.step!.id === transitNode)
        .map((article) =>
          article.with('request', request).with('organization', request.organization)
        )
    : [];

  const assignedWorkshop = articlesReadyForShipment[0]?.workshop;
  const atWorkshop = articlesReadyForShipment[0]?.atWorkshop;
  const assignedWorkshopAddress = assignedWorkshop
    ? newAddressWithRelations(assignedWorkshop.address, null, null, assignedWorkshop)
    : undefined;
  const atWorkshopAddress = atWorkshop
    ? newAddressWithRelations(atWorkshop.address, null, null, atWorkshop)
    : undefined;

  const articlesHaveDifferentWorkshops = articlesReadyForShipment.find(
    (article) => article.workshop?.id !== assignedWorkshop?.id
  );

  const stepConfig = articlesReadyForShipment[0]?.step?.config as
    | TransitStepConfig['config']
    | undefined;

  const initialOrigin =
    stepConfig?.originType === 'client'
      ? clientAddress
      : stepConfig?.originType === 'store'
        ? storeAddress
        : stepConfig?.originType === 'workshop'
          ? atWorkshopAddress
          : undefined;

  const initialDestination =
    stepConfig?.destinationType === 'client'
      ? clientAddress
      : stepConfig?.destinationType === 'store'
        ? storeAddress
        : stepConfig?.destinationType === 'workshop'
          ? assignedWorkshopAddress
          : undefined;

  if (articlesHaveDifferentWorkshops) {
    return <NewDrawer initialOrigin={initialOrigin} />;
  } else {
    return (
      <NewDrawer
        initialOrigin={initialOrigin}
        initialDestination={initialDestination}
        initialArticles={articlesReadyForShipment}
      />
    );
  }
};

const NewDrawer = ({
  initialOrigin,
  initialDestination,
  initialArticles = [],
}: {
  initialOrigin?: AddressWithRelations;
  initialDestination?: AddressWithRelations;
  initialArticles?: ArticleWithRelations[];
}) => {
  const { _ } = useLingui();
  const { isMobile } = useViewPort();
  const navigate = useNavigate();

  const [origin, setOrigin] = useState<AddressWithRelations | undefined>(initialOrigin);
  const [destination, setDestination] = useState<AddressWithRelations | undefined>(
    initialDestination
  );
  const [articles, setArticles] = useState<ArticleWithRelations[]>(initialArticles);
  const [carrierService, setCarrierService] = useState<
    ShipmentCarrierService | 'private-carrier'
  >();
  const [handover, setHandover] = useState<ShipmentHandover>();
  const [pickupDate, setPickupDate] = useState<string>();
  const [trackingId, setTrackingId] = useState<string>();
  const [error, setError] = useState<string>();
  const [alertRef, scrollAlertIntoView] = useScrollIntoView<HTMLDivElement>();

  const { mutateAsync: createShipment, isPending } = useCreateShipment();

  const handleError = (error: string) => {
    setError(error);
    scrollAlertIntoView();
  };

  const onSubmit = () => {
    if (!origin) {
      handleError(_(msg({ id: 'shipments.new.error.origin', message: 'Please select an origin' })));
      return;
    }

    if (!destination) {
      handleError(
        _(msg({ id: 'shipments.new.error.destination', message: 'Please select a destination' }))
      );
      return;
    }

    if (articles.length === 0) {
      handleError(
        _(msg({ id: 'shipments.new.error.articles', message: 'Please add at least one item' }))
      );
      return;
    }

    if (!carrierService) {
      handleError(
        _(msg({ id: 'shipments.new.error.carrier', message: 'Please select a carrier' }))
      );
      return;
    }

    if (!!carrierService && carrierService !== 'private-carrier' && !handover) {
      handleError(
        _(
          msg({
            id: 'shipments.new.error.handover',
            message: 'Please select a handover option',
          })
        )
      );
      return;
    }

    if (handover === 'pickup' && carrierService !== 'private-carrier' && !pickupDate) {
      handleError(
        _(
          msg({
            id: 'shipments.new.error.pickup-date',
            message: 'Please select a pickup date',
          })
        )
      );
      return;
    }

    createShipment({
      origin: origin.client?.id ?? origin.store?.id ?? origin.workshop?.id ?? '',
      destination:
        destination.client?.id ?? destination.store?.id ?? destination.workshop?.id ?? '',
      articles: articles.map((article) => article.id),
      carrierService,
      handover: carrierService === 'private-carrier' ? 'pickup' : handover,
      pickupDate:
        handover === 'pickup' && carrierService !== 'private-carrier' ? pickupDate : undefined,
      trackingId: carrierService === 'private-carrier' ? trackingId : undefined,
    })
      .then((shipment: Shipment) => {
        navigate(`/shipments/${shipment.id}`, { state: { skipRouterBlocker: true } });
      })
      .catch((err) => {
        console.error(err);
        setError(
          (err.message as string) ??
            _(msg({ id: '_general.error.unknown', message: 'Unknown error' }))
        );
      });
  };

  return (
    <PageLayout>
      <PageLayoutSimpleHeader
        onClose={() => navigate('/shipments', { state: { skipRouterBlocker: false } })}
        title={_(msg({ id: 'shipments.new.title', message: 'Create a new delivery' }))}
      />
      <PageLayoutContent>
        <PageLayoutLeftPart>
          <PageLayoutLeftPartContent>
            <Stack padding={isMobile ? '1rem' : undefined} ref={alertRef} gap="1rem">
              {!!error && (
                <AlertBar
                  type="error"
                  title={_(
                    msg({
                      id: 'shipments.new.error',
                      message:
                        'Please fill the missing information in order to be able to create a shipment',
                    })
                  )}
                />
              )}
              <ShipmentForm
                {...{
                  origin,
                  setOrigin,
                  destination,
                  setDestination,
                  articles,
                  setArticles,
                  carrierService,
                  setCarrierService,
                  handover,
                  setHandover,
                  pickupDate,
                  setPickupDate,
                  trackingId,
                  setTrackingId,
                  error,
                }}
              />
            </Stack>
          </PageLayoutLeftPartContent>

          <PageLayoutLeftPartFooter>
            <Button variant="primary" onPress={onSubmit} isLoading={isPending}>
              <Trans id="shipments.new.create">Create a shipment</Trans>
            </Button>
          </PageLayoutLeftPartFooter>
        </PageLayoutLeftPart>
      </PageLayoutContent>
    </PageLayout>
  );
};

export default New;
